<template src="./pricePlanner.html"></template>

<script>
import groupEditor from './groupEditor/groupEditor';
import Vue from 'vue';
export default {
  name: 'pricePlanner',
  components: { groupEditor },
  data: () => ({
    table: {
      flags: {
        dateShow: false,
        nowPrice: true,
      },
      dateNow: new Date().toISOString().slice(0, 10),
      dateSelect: '',
      groupBy: 'group',
      groupByShow: true,
      headers: [
        { text: 'ID', value: 'id',sortable: false },
        { text: 'Коллектив', value: 'group',sortable: false },
        { text: 'Группа', value: 'full_name',sortable: false },
        { text: 'Цена сейчас', value: 'price', align: 'center',sortable: false },
      ],
      groups: [],
      search: '',
      select_team:0,
      select: [],
    },
  }),
  created() {
    this.groupLoad();
  },
  methods: {
    groupLoad() {
      let pr2 = this.$store.state.api_dk.LoadTeam();
      pr2.then((data) => {
        this.table.dateSelect = ''
        this.table.groups.splice(0)
        this.table.groups = this.reGroup(data);
        this.table.flags.nowPrice = true;
        this.table.headers.find(el=>el.value === 'price').text = 'Цена сейчас';
      })
    },
    findPriceMounth(){
        let pr2 = this.$store.state.api_dk.FindPriceMounth(this.table.dateSelect+'-01%2000:00:02');
        pr2.then((data) => {
          console.log('Цены на момент ' + this.table.dateSelect+'-01%2000:00:02')

          this.table.groups.splice(0);
          this.table.groups = this.reGroup(data);

          this.table.headers.find(el=>el.value === 'price').text = 'Цена на ' + this.table.dateSelect;
          this.table.flags.nowPrice = false
        })
    },

    reGroup(data) {
      let newData = [];
      data.forEach((item) => {
        switch (item.type) {
          case 1:
            item.group = item.name;
            newData.push(item);
            break;
          case 2:
            item.child.forEach((subGroup) => {
              subGroup.group = item.name;
              newData.push(subGroup);
            });
            break;
        }
      });
      newData.sort(function (a, b) {
        if (Number(a.id) > Number(b.id)) {
          return 1;
        }
        if (Number(a.id) < Number(b.id)) {
          return -1;
        }
        // a должно быть равным b
        return 0;
      });
      return newData;
    },
    itemSelect(e) {
      this.$refs.groupEditor.flags.show = true;
      this.table.select = [];
      this.table.select.push(e);
      this.table.select_team = e.id;
      this.$refs.groupEditor.loadTeam(e.id);
    },
    onGroupUpdate(data) {
      console.log(data);
      let element, elementIndex;
      switch (data.type) {
        case 'update':
          this.table.select = [];
          console.log(this.table.groups);
          element = this.table.groups.find((el) => el.id === data.data.id);
          for (let key in data.data) {
            if (key === 'id') {
              continue;
            }
            element[key] = data.data[key];
          }
          console.log(element);
          elementIndex = this.table.groups.findIndex(
            (el) => el.id === data.data.id
          );
          Vue.set(this.table.groups, elementIndex, element);
          break;
      }
      this.table.select = [];
    }
  },
};
</script>

<style scoped></style>
