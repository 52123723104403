var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('groupEditor',{ref:"groupEditor",staticClass:"pa-3 pb-0",attrs:{"team_id":_vm.table.select_team},on:{"onGroupUpdate":_vm.onGroupUpdate}})],1)],1),_c('v-row',{staticClass:"mb-0 pb-0"},[_c('v-toolbar',{staticClass:"mx-3",attrs:{"color":"indigo","dark":""}},[_c('v-col',{staticClass:"pt-10",attrs:{"cols":"4"}},[_c('v-dialog',{ref:"date_table",attrs:{"return-value":_vm.table.dateSelect,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.table, "dateSelect", $event)},"update:return-value":function($event){return _vm.$set(_vm.table, "dateSelect", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Выберите дату показа цен","prepend-icon":"mdi-calendar","filled":"","color":"blue-grey lighten-2","readonly":"","hint":"Начало активности новой цены","persistent-hint":""},model:{value:(_vm.table.dateSelect),callback:function ($$v) {_vm.$set(_vm.table, "dateSelect", $$v)},expression:"table.dateSelect"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.table.flags.dateShow),callback:function ($$v) {_vm.$set(_vm.table.flags, "dateShow", $$v)},expression:"table.flags.dateShow"}},[_c('v-date-picker',{attrs:{"show-current":false,"no-title":"","scrollable":"","locale":"ru-RU","type":"month"},model:{value:(_vm.table.dateSelect),callback:function ($$v) {_vm.$set(_vm.table, "dateSelect", $$v)},expression:"table.dateSelect"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.table.flags.dateShow = false}}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.date_table.save(_vm.table.dateSelect);_vm.findPriceMounth()}}},[_vm._v(" Выбрать ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"green",attrs:{"disabled":_vm.table.flags.nowPrice,"block":"","height":"42"},on:{"click":_vm.groupLoad}},[_vm._v(" Показать текущие цены ")])],1)],1)],1),_c('v-row',{staticClass:"mt-0 pt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.table.headers,"items":_vm.table.groups,"search":_vm.table.search,"item-key":"id","items-per-page":50,"single-select":"","group-by":_vm.table.groupBy,"no-data-text":"Группы не подгрузились","footer-props":{
                   'items-per-page-text':'Групп на странице',
                   'items-per-page-all-text':'Все'
                  }},on:{"click:row":_vm.itemSelect},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
                  var items = ref.items;
                  var isOpen = ref.isOpen;
                  var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":_vm.table.headers.length}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',[_vm._v("Коллектив "),_c('b',[_vm._v(_vm._s(items[0].group)+":")])])],1)]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" руб. ")]}},{key:"item.newPrice",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.newPrice !== undefined ? item.newPrice + ' руб.' : '')+" ")]}}]),model:{value:(_vm.table.select),callback:function ($$v) {_vm.$set(_vm.table, "select", $$v)},expression:"table.select"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }