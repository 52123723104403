<template src="./groupEditor.html"></template>

<script>
export default {
  name: 'groupEditor',
  props:{
    team_id:{type:Number,default:0}
  },
  data: () => ({
    flags: {
      alert: {
        flag:false,
        type:0,
        types:[
          {type:"success",text:"Цена сохранена (можно добавить еще)"},
          {type:"error",text:"Что то пошло не так"},
        ]
      },
      show: false,//показ dialog окна формы
      load: false,//статус загрузки данных в форму
      panel: [0,1],//номера открытых панелей
      valid: false,//статус готовности к сохранению
      date_start: false,//показ dialog окна изменения даты
    },
    dateNow: new Date().toISOString().slice(0, 10),
    modifierData: {},
    editGroup: {},
    emptyGroup:{
      id:0,
      name:"",
      full_name:"",
      type:"",
      parent_id:"",
      price:0,
      history:[],

      newPrice: "",
      date_start: '',
    },
    DB_newPrice:{},
    priceHistory: [],
  }),
  created() {
    this.clearForm();
  },
  methods: {
    loadTeam(team_id){
      this.clearForm();
      let pr2 = this.$store.state.api_dk.LoadTeamData(team_id);
      pr2.then((data) => {
        this.DB_newPrice = Object.assign({},data)//отладка

        this.editGroup = Object.assign({},this.emptyGroup)

        for(let key in data){
          this.editGroup[key] = data[key]
        }
        this.flags.load = true;
      })
    },
    clearForm() {
      this.modifierData = Object.assign({}, {});
      this.editGroup = Object.assign({}, this.emptyGroup);
    },
    onlyNumber(evt) {
      evt = evt ? evt : window.event;
      let whiteList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      let result = whiteList.find((el) => el === evt.key);

      if (result !== undefined) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    checkForm(type) {
      this.modifierData.id = this.editGroup.id;
      this.modifierData[type] = this.editGroup[type];

      if(this.modifierData[type] === "") delete this.modifierData[type]

      let length = Object.keys(this.modifierData).length;
      if (length > 2) {
        this.flags.valid = true;
      } else {
        this.flags.valid = false;
      }
    },
    closeEdit() {
      this.clearForm();
      this.editGroup = Object.assign({}, this.emptyGroup);
      this.modifierData = Object.assign({}, {});
      this.flags.show = false;
      this.flags.valid = false;
      this.flags.alert.flag = false;

      let dataPull = { type: 'close' };
      this.$emit('onGroupUpdate', dataPull);
    },
    clearDate(type) {
      switch (type) {
        case 'date_start':
          this.editGroup.date_start = '';
        break;
      }
      this.checkForm(type)
     },
    saveStartPrice(){
      let {date_start,newPrice} = this.modifierData
      let post_data = {
          team_id:  this.team_id,
          price:    newPrice,
          start:    date_start
      }
      let pr2 = this.$store.state.api_dk.AddStartPrice(post_data);
      pr2.then((data) => {
        this.flags.alert.flag = true;
        switch(data.b){
         case true:
           this.flags.alert.type = 0;
           this.loadTeam(this.team_id)
         break;
         case undefined:
         case false:
           this.flags.alert.type = 1;
         break;
        }
      })
    }
  },
};
</script>

<style scoped></style>
